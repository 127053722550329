import Button from "../../Button";
import CodeInput from "../../CodeInput";
import warning from "../../../assets/img/warning.svg";
import styles from "./index.module.scss";
import { ISecondContent } from "../../../interfaces/ICode";

const SecondContent: React.FC<ISecondContent> = ({
  driverNumberCheck,
  setDriverNumber,
  nextStep,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_form}>
      <div className={styles.wrapper_form_text}>
        <div className={styles.wrapper_form_text__title}>
          שלב 2/3 בדרך ליעד:
        </div>
        <div className={styles.wrapper_form_text__description}>אימות פרטים</div>
      </div>
      <div className={styles.wrapper_form_code}>
        <div className={styles.wrapper_form_code__title}>מספר נהג Gett</div>
        <CodeInput
          allowedCharacters={"numeric"}
          onChange={(value) => {
            setDriverNumber(value);
          }}
          check={driverNumberCheck}
        />
        {driverNumberCheck && <div className={styles.wrapper_form_code_warning}>
          <img src={warning} alt="Warning" />
          <span className={styles.wrapper_form_code_warning__text}>
            מספר הנהג כבר קיים במערכת
          </span>
        </div>}
      </div>
    </div>
    <Button
      onClick={() => {
        nextStep();
      }}
      text={"לבחירת מוסך להתקנה בחינם"}
    />
  </div>
);

export default SecondContent;
