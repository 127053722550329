import instance from "./instance";

export const CodeApi = {
  createUser(data: { name: string; phone: string }) {
    return instance
      .post("/code/createUser", data)
      .then((response: any) => response.data)
      .catch((err) => err.response.data);
  },
  verifyDriverNumber(data: { driverNumber: string }) {
    return instance
      .post("/code/verifyDriverNumber", data)
      .then((response: any) => response.data)
      .catch((err) => err.response.data);
  },
};

export const CarApi = {
  getCar(carNumber: string) {
    return instance
      .get(`/car?carNumber=${carNumber}`)
      .then((response: any) => response.data)
      .catch((err) => err.response.data);
  },
  details(data: {
    manufacture: string;
    manufactureYear: string;
    carNumber: string;
  }) {
    return instance
      .put("/car/details", data)
      .then((response: any) => response.data)
      .catch((err) => err.response.data);
  },
  getDetailsByDriverNumber( driverNumber: string | number) {
    return instance
      .get(`/car/byDriverNumber?driverNumber=${driverNumber}`)
      .then((response: any) => response.data)
      .catch((err) => err.response.data);
  },
};
