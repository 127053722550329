import { IButton } from "../../interfaces/IButton";
import styles from "./index.module.scss";

const Button: React.FC<IButton> = ({ text, backgroundColor, onClick }) => (
  <button
    className={`${styles.wrapper} ${
      backgroundColor === "white"
        ? styles.backgroundWhite
        : styles.backgroundGreen
    }`}
    onClick={onClick}
  >
    {text}
  </button>
);

export default Button;
