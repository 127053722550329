import styles from "./index.module.scss";
import React, { useEffect, useRef, useState } from "react";
// import { QrReader } from "react-qr-reader";
import qr from "../../assets/img/qr.png";
import outline from "../../assets/img/outline.png";
import deleteIcon from "../../assets/img/delete.svg";
import { toast } from "react-toastify";
import { Html5Qrcode } from "html5-qrcode";

let html5QrCode: any;

const QrReaderComponent: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    html5QrCode = new Html5Qrcode("reader");
  }, []);

  const onResult = (res: any) => {
    setData(res);
    setIsActive(false);
  };
  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = (decodedText: any, decodedResult: any) => {
      onResult(decodedText);
      handleStop();
    };
    html5QrCode.start(
      { facingMode: "environment" },
      { fps: 10 },
      qrCodeSuccessCallback
    );
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res: any) => {
          html5QrCode.clear();
        })
        .catch((err: any) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        className={`${styles.wrapper} ${
          data ? styles.whiteBackground : styles.greenBackground
        }`}
        onClick={() => {
          if (!data && !isActive) {
            setIsActive(true);
            handleClickAdvanced();
          } else if (!data && isActive) {
            setIsActive(false);
            handleStop();
          }
        }}
      >
        <div
          id="reader"
          className={isActive ? styles.reader : styles.hidden}
        ></div>
        {data ? (
          <>
            <img
              src={deleteIcon}
              className={styles.deleteIcon}
              alt="Delete"
              onClick={() => {
                setData(null);
              }}
            />
            <img src={outline} alt="Outline" />
            <div>ABC ןנסמ</div>
          </>
        ) : isActive ? (
          <></>
        ) : (
          <>
            <img src={qr} alt="qr" />
            <div>QR סריקת קוד </div>
          </>
        )}
      </div>
    </>
  );
};

export default QrReaderComponent;
