import styles from "./index.module.scss";
import footerLogo from "../../../assets/img/footerLogo.png";
import footerLogoWhite from "../../../assets/img/footerLogoWhite.png";

const Footer: React.FC<{color?: 'green' | 'white'}> = ({color}) => (<div className={styles.wrapper}>
  <img src={color === 'white' ? footerLogoWhite : footerLogo} alt="Footer logo" />
</div>
);

export default Footer;
