import { useEffect } from "react";
import RoutesComponent from "./routes";

const App = () => {
  useEffect(() => {
    const viewHeight = window.innerHeight;
    const viewport: any = document.querySelector("meta[name=viewport]");
    viewport.setAttribute(
      "content",
      "height=" +
        viewHeight +
        "px, width=device-width, initial-scale=1, maximum-scale=1"
    );
  }, []);

  return <RoutesComponent />;
};

export default App;
