import styles from "./index.module.scss";
import RegistrationHeader from "../../components/Code/Header";
import TextInput from "../../components/TextInput";
import RegistrationFooter from "../../components/Code/Footer";
import Button from "../../components/Button";
import QrReaderComponent from "../../components/QrReader";
import { IProfile } from "../../interfaces/IProfile";
import Complete from "../../components/Complete";

const Profile: React.FC<IProfile> = ({
  name,
  driverNumber,
  manufacture,
  manufactureYear,
  model,
  getCar,
  setDriverNumber,
  setManufacture,
  setManufactureYear,
  setName,
  setModel,
  isCompleted,
  complete,
}) =>
  isCompleted ? (
    <Complete />
  ) : (
    <div className={styles.wrapper}>
      <RegistrationHeader />
      <div className={styles.wrapper_form}>
        <div className={styles.wrapper_form__title}>הזן קוד קופון בבקשה</div>
        <TextInput
          type={"text"}
          title={"מספר נהג GETT"}
          placeholder={""}
          value={driverNumber}
          onChange={(value) => setDriverNumber(value)}
        />
        <div className={styles.wrapper_form__carApprove}>
          <span onClick={() => getCar()}>אישור</span>
        </div>
        <TextInput
          type={"text"}
          title={"שם מלא"}
          placeholder={""}
          value={name}
          onChange={(value) => setName(value)}
          disabled
        />
        <TextInput
          type={"text"}
          title={"יצרן"}
          placeholder={""}
          value={manufacture}
          onChange={(value) => setManufacture(value)}
          disabled
        />
        <TextInput
          type={"text"}
          title={"דגם"}
          placeholder={""}
          value={model}
          onChange={(value) => setModel(value)}
          disabled
        />
        <div className={styles.wrapper_form__line}></div>
        <div className={styles.wrapper_form_qr__title}>בחירת מסנן</div>
        <QrReaderComponent />
        <Button text="שמור" onClick={() => complete()} />
        <RegistrationFooter />
      </div>
    </div>
  );

export default Profile;
