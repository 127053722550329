import { BrowserRouter, Routes, Route } from "react-router-dom";
import useToken from "./helpers/useToken";
import ProfileContainer from "./pages/Profile/container";
import CodeContainer from "./pages/Code/container";
// import LoginContainer from "./pages/Login/container";
// import DashboardContainer from "./pages/Dashboard/container";
// import ManageModeratorsContainer from "./pages/ManageModerators/container";
// import ManageAvatarsContainer from "./pages/ManageAvatars/container";
// import ManagePhotoContainer from "./pages/ManagePhoto/container";

const RoutesComponent: React.FC<{}> = () => {
  const [token, setToken] = useToken();

  // if (!token) {
    // return <LoginContainer setToken={setToken} />;
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<CodeContainer />} />
        <Route path="/profile" element={<ProfileContainer />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
