import { useState } from "react";
import Profile from ".";
import { CarApi } from "../../api/api";

const ProfileContainer: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [driverNumber, setDriverNumber] = useState<string>("");
  const [manufacture, setManufacture] = useState<string>("");
  const [manufactureYear, setManufactureYear] = useState<string>("");
  const [model, setModel] = useState<string>("");

  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  const getCar = async () => {
    const response = await CarApi.getDetailsByDriverNumber(driverNumber);
    if (response.success) {
      setName(response.data.name);
      setManufactureYear(response.data.car.manufactureYear);
      setManufacture(response.data.car.manufacture);
      setModel(response.data.car.model);
    } else {
      alert(response.message);
    }
  };

  const complete = () => {
    setIsCompleted(true);
  };

  return (
    <Profile
      isCompleted={isCompleted}
      name={name}
      driverNumber={driverNumber}
      manufacture={manufacture}
      manufactureYear={manufactureYear}
      model={model}
      setName={setName}
      setDriverNumber={setDriverNumber}
      setManufacture={setManufacture}
      setManufactureYear={setManufactureYear}
      setModel={setModel}
      getCar={getCar}
      complete={complete}
    />
  );
};

export default ProfileContainer;
