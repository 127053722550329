import styles from "./index.module.scss";
import shape from "../../assets/img/shape.svg";
import footerLogoWhite from "../../assets/img/footerLogoWhite.png";

const Complete: React.FC<{ navigate?: any }> = ({ navigate }) => (
  <div
    className={styles.wrapper}
    onClick={() => {
      if (navigate) { 
        navigate("/profile");
      }
    }}
  >
    <div className={styles.wrapper_header}>
      <img src={shape} alt="Shape" />
      <div className={styles.wrapper_header__text}>הגעת ליעד!</div>
    </div>
    <div className={styles.wrapper_text__title}>
      אתה והנוסעים שלך בדרך לאוויר נקי ובטוח
    </div>
    <div className={styles.wrapper_text__description}>
      סמס עם פרטי המוסך נשלח לטלפון אותו הזנת.
      <br />
      <br />
      לתיאום ההתקנה צור קשר:
      <br />
      03-57987598 מוסך האחים ובניו בע״מ, דרך השלום 32, ת״א
    </div>
    <div>
      <img src={footerLogoWhite} alt="Logo" />
    </div>
  </div>
);

export default Complete;
