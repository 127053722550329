import Button from "../../Button";
import TextInput from "../../TextInput";
import styles from "./index.module.scss";
import RegistrationFooter from "../Footer";
import { ICodeContent } from "../../../interfaces/ICode";

const PreviewContent: React.FC<ICodeContent> = ({ nextStep }) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_text__title}>
      המסנן היחיד שלא רק מסנן את ההחיידקים והבקטריות אלא גם קוטל אותם
      בטכנולוגיית ״טוטאל-קיל״.
      <br />+ מבוסס על פטנט ייחודי לתחום הרפואה
      <br />+ הופך את מזגן הרכב למטהר אוויר אקטיבי
      <br />+ קל להתקנה על מערכת המיזוג הקיימת
    </div>
    <div className={styles.wrapper_text__description}>
      נסיעה בריאה - בהגנה כפולה: עבורך והנוסעים
    </div>
    <RegistrationFooter color={"white"} />
    <Button
      onClick={() => {nextStep()}}
      text={"להרשמה להתקנה בחינם"}
      backgroundColor={"white"}
    />
  </div>
);

export default PreviewContent;
