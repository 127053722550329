import { ISelector } from "../../interfaces/ISelector";
import styles from "./index.module.scss";

const Selector: React.FC<ISelector> = ({ onChange, options, title }) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>{title}</div>
    <div className={styles.wrapper_selector}>
      <select
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map(({ value, text }: any) => (
          <option value={value}>{text}</option>
        ))}
      </select>
    </div>
  </div>
);

export default Selector;
