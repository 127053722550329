import Button from "../../Button";
import TextInput from "../../TextInput";
import warning from "../../../assets/img/warning.svg";
import styles from "./index.module.scss";
import { IThirdContent } from "../../../interfaces/ICode";
import Selector from "../../Selector";

const ThirdContent: React.FC<IThirdContent> = ({
  carNumber,
  manufacture,
  manufactureYear,
  garage,
  getCar,
  setCarNumber,
  setManufacture,
  setManufactureYear,
  setGarage,
  nextStep,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_form}>
      <div className={styles.wrapper_form_text}>
        <div className={styles.wrapper_form_text__title}>
          שלב 3/3 בדרך ליעד:
        </div>
        <div className={styles.wrapper_form_text__description}>בחירת מוסך</div>
      </div>
      <TextInput
        title="מספר רכב"
        placeholder="מספר רכב"
        type="tel"
        value={carNumber}
        onChange={(value) => setCarNumber(value)}
      />
      <div className={styles.wrapper_form__carApprove}>
        <span onClick={() => getCar()}>אישור</span>
      </div>
      <div className={styles.wrapper_form_combineInputs}>
        <TextInput
          title="יצרן"
          placeholder="יצרן"
          type="tel"
          value={manufactureYear}
          onChange={(value) => setManufactureYear(value)}
        />
        <TextInput
          title="שנת ייצור"
          placeholder="שנת ייצור"
          type="text"
          value={manufacture}
          onChange={(value) => setManufacture(value)}
        />
      </div>
      {/* <TextInput
        title="לבחירת מוסך מרשימה"
        placeholder="לבחירת מוסך מרשימה"
        type="tel"
        value={garage}
        onChange={(value) => setGarage(value)}
      /> */}
      <Selector
        title="לבחירת מוסך מרשימה"
        options={[
          { value: "asd", text: "asd" },
          { value: "asdg", text: "asdg" },
        ]}
        onChange={(value) => setGarage(value)}
      />
      <div className={styles.wrapper_form_warning}>
        <img src={warning} alt="Warning" />
        <span className={styles.wrapper_form_warning__text}>
          לא ניתן לשנות את מיקום ההתקנה לאחר בחירה
        </span>
      </div>
    </div>
    <Button
      onClick={() => {
        nextStep();
      }}
      text={"סיום ושליחת הטופס"}
    />
  </div>
);

export default ThirdContent;
