import styles from "./index.module.scss";
import RegistrationHeader from "../../components/Code/Header";
import RegistrationFooter from "../../components/Code/Footer";
import FirstCodeContent from "../../components/Code/FirstContent";
import SecondCodeContent from "../../components/Code/SecondContent";
import ThirdCodeContent from "../../components/Code/ThirdContent";
import { ICode } from "../../interfaces/ICode";
import PreviewContent from "../../components/Code/PreviewContent";
import Complete from "../../components/Complete";
import { useNavigate } from "react-router-dom";

const Code: React.FC<ICode> = ({
  step,
  check,
  fullName,
  phone,
  carNumber,
  manufacture,
  manufactureYear,
  garage,
  nextStep,
  getCar,
  setFullName,
  setPhone,
  setDriverNumber,
  setCarNumber,
  setManufacture,
  setManufactureYear,
  setGarage,
}) => {
  const navigate = useNavigate();
  switch (step) {
    case 0:
      return (
        <div className={styles.previewWrapper}>
          <div className={styles.previewWrapper_header}>
            <RegistrationHeader />
            <div className={styles.previewWrapper_header_text}>
              <div>היעד: אוויר נקי</div>
              <div className={styles.previewWrapper_header_text__number}>
                30
              </div>
              <div>שניות</div>
            </div>
          </div>
          <PreviewContent nextStep={() => nextStep(1)} />
        </div>
      );
    case 1:
      return (
        <div className={styles.wrapper}>
          <RegistrationHeader />
          <FirstCodeContent
            fullName={fullName}
            phone={phone}
            setFullName={setFullName}
            setPhone={setPhone}
            nextStep={() => nextStep(2)}
          />
          <RegistrationFooter />
        </div>
      );
    case 2:
      return (
        <div className={styles.wrapper}>
          <RegistrationHeader />
          <SecondCodeContent
            driverNumberCheck={check.driverNumber}
            setDriverNumber={setDriverNumber}
            nextStep={() => nextStep(3)}
          />
          <RegistrationFooter />
        </div>
      );
    case 3:
      return (
        <div className={`${styles.wrapper} ${styles.dynamicHeight}`}>
          <RegistrationHeader />
          <ThirdCodeContent
            carNumber={carNumber}
            manufacture={manufacture}
            manufactureYear={manufactureYear}
            garage={garage}
            getCar={getCar}
            setCarNumber={setCarNumber}
            setManufacture={setManufacture}
            setManufactureYear={setManufactureYear}
            setGarage={setGarage}
            nextStep={() => nextStep(4)}
          />
          <RegistrationFooter />
        </div>
      );
    case 4:
      return <Complete navigate={navigate} />;
    default:
      return <></>;
  }
};

export default Code;
