import { ITextInput } from "../../interfaces/ITextInput";
import styles from "./index.module.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const TextInput: React.FC<ITextInput> = ({
  title,
  direction,
  type,
  disabled,
  placeholder,
  value,
  onChange,
  className,
  isChecked,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>{title}</div>
    {type === "phone" ? (
      <PhoneInput
        defaultCountry="IL"
        placeholder={placeholder}
        value={value}
        className={styles.phoneInput}
        onChange={(value) => onChange(value as string)}
        countryCallingCodeEditable={false}
        international
      />
    ) : (
      <input
        type={type}
        placeholder={placeholder}
        className={`${styles.textInput}${
          direction === "ltr" ? " " + styles.ltr : ""
        }${className ? " " + className : ""}`}
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        style={isChecked ? { borderColor: "red" } : undefined}
      />
    )}
  </div>
);

export default TextInput;
