import { IFirstContent } from "../../../interfaces/ICode";
import Button from "../../Button";
import TextInput from "../../TextInput";
import styles from "./index.module.scss";

const FirstContent: React.FC<IFirstContent> = ({
  fullName,
  phone,
  setFullName,
  setPhone,
  nextStep,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper_form}>
      <div className={styles.wrapper_form_text}>
        <div className={styles.wrapper_form_text__title}>
          שלב 1/3 בדרך ליעד:
        </div>
        <div className={styles.wrapper_form_text__description}>
          רישום קצר להתקנה קלה
        </div>
      </div>
      <TextInput
        title="שם מלא"
        placeholder="שם מלא"
        type="text"
        value={fullName}
        onChange={(value) => setFullName(value)}
      />
      <TextInput
        title="מספר טלפון"
        placeholder="מספר טלפון"
        type="tel"
        direction="ltr"
        value={phone}
        onChange={(value) => setPhone(value)}
      />
      <label className={styles.wrapper_form_checkbox}>
        <input
          className={styles.wrapper_form_checkbox__input}
          type="checkbox"
          //   checked={checked}
          //   onChange={handleChange}
        />
        <span className={styles.wrapper_form_checkbox__text}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo, quae?
        </span>
      </label>
    </div>
    <Button
      onClick={() => {
        nextStep();
      }}
      text={"לשליחת פרטים"}
    />
  </div>
);

export default FirstContent;
