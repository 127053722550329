import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Code from ".";
import { CarApi, CodeApi } from "../../api/api";
import logout from "../../helpers/logout";
import useToken from "../../helpers/useToken";
import { ICode } from "../../interfaces/ICode";

const CodeContainer: React.FC = () => {
  const [token, setToken] = useToken();
  const [step, setStep] = useState<number>(0);
  const [fullName, setFullName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [driverNumber, setDriverNumber] = useState<string>("");
  const [carNumber, setCarNumber] = useState<string>("");
  const [manufacture, setManufacture] = useState<string>("");
  const [manufactureYear, setManufactureYear] = useState<string>("");
  const [garage, setGarage] = useState<string>("");
  const [check, setCheck] = useState<ICode["check"]>({
    fullName: false,
    phone: false,
    driverNumber: false,
    carNumber: false,
    manufacture: false,
    manufactureYear: false,
    garage: false,
  });

  useEffect(() => {
    if (check.driverNumber) {
      setCheck((oldValue) => ({ ...oldValue, driverNumber: false }));
    }
  }, [driverNumber]);

  useEffect(() => {
    return () => {
      logout()
    }
  }, []);

  const nextStep = async (step: number) => {
    switch (step) {
      case 1:
        setStep(1);
        break;
      case 2:
        if (fullName && phone) {
          const response = await CodeApi.createUser({ name: fullName, phone });
          if (response.success) {
            setToken(response.data.token);
            setStep(2);
          } else {
            toast.error(response.message);
          }
        } else {
          toast.error("contain all fields");
        }
        break;
      case 3:
        if (driverNumber) {
          const response = await CodeApi.verifyDriverNumber({ driverNumber });
          if (response.success) {
            setStep(3);
          } else {
            toast.error(response.message);
            setCheck((oldValue) => ({ ...oldValue, driverNumber: true }));
          }
        } else {
          toast.error("contain all fields");
          setCheck((oldValue) => ({ ...oldValue, driverNumber: true }));
        }
        break;
      case 4:
        if (manufacture && manufactureYear && carNumber) {
          const response = await CarApi.details({
            carNumber,
            manufacture,
            manufactureYear,
          });
          if (response.success) {
            setStep(4);
          } else {
            toast.error(response.message);
          }
        } else {
          toast.error("contain all fields");
        }
        break;
    }
  };

  const getCar = async () => {
    const response = await CarApi.getCar(carNumber);
    if (response.success) {
      setManufactureYear(response.data.shnat_yitzur);
      setManufacture(response.data.tozeret_nm);
      // setModel(response.data.model);
    } else {
      setManufactureYear("");
      setManufacture("");
      toast.error(response.message);
    }
  };

  return (
    <Code
      step={step}
      check={check}
      fullName={fullName}
      phone={phone}
      carNumber={carNumber}
      manufacture={manufacture}
      manufactureYear={manufactureYear}
      garage={garage}
      nextStep={nextStep}
      getCar={getCar}
      setFullName={setFullName}
      setPhone={setPhone}
      setDriverNumber={setDriverNumber}
      setCarNumber={setCarNumber}
      setManufacture={setManufacture}
      setManufactureYear={setManufactureYear}
      setGarage={setGarage}
    />
  );
};

export default CodeContainer;
