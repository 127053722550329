import axios from "axios";
import logout from "../helpers/logout";

// const token = JSON.parse(localStorage.getItem("InexApiToken") as string);
const prodUrl = "https://inex.bsx-group.com:2022/api";
const devUrl = "http://localhost:3333/api";

export const dataAxios: {
  baseURL: string;
//   headers: undefined | { [key: string]: any };
} = {
  baseURL: process.env.NODE_ENV === "production" ? prodUrl : devUrl,
//   headers: token ? { Authorization: `Bearer ${token}` } : undefined,
};

const instance = axios.create(dataAxios);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("[error]", error);
    const status = error.request.status;
    if (status === 401) {
      const errorData = error.request.response;
      const errorType = JSON.parse(errorData);
      if (errorType.message === "Unauthenticated.") {
        const token = localStorage.getItem("InexApiToken");
        if (token) {
          logout();
        }
      }
    }
    if (error.response?.data?.errors) {
      Object.values(error.response?.data?.errors)?.forEach((string) =>
        alert(string as string)
      );
    }
    return Promise.reject(error);
  }
);

export default instance;
